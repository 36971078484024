import { PAGE_NAME, getPageURL } from 'services/partnerPortal.service';

export const getBreadCrumbs = ({ props }) => [
  { name: 'Home', link: getPageURL({ pageName: PAGE_NAME.HOME.label }) },
  ...props
];

export const copyToClipBoard = (text) =>
  navigator.clipboard?.writeText(text).then();

export const isBrowser = () => typeof window !== 'undefined';

export const parseNumberedLabel = (value) =>
  value && value !== 'NA' ? `# ${value}` : 'NA';
