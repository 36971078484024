const tailwindcssRtl = require('tailwindcss-rtl');

const STATIC_MEDIA_STORE_PATH_STUB = '/static_images/partner';
const mediaStoreBaseURL =
  process.env.STORYBOOK_PUBLIC_MEDIA_STORE_URL ||
  process.env.NEXT_PUBLIC_MEDIA_STORE_URL ||
  '';
const staticMediaStoreBaseURL = `${mediaStoreBaseURL}${STATIC_MEDIA_STORE_PATH_STUB}`;

const colors = {
  'base-white': '#FCFCFC',
  blue: '#2D57EC',
  brand: '#EA4842',
  'dim-gray': '#717171',
  'green-base': '#29823B',
  'green-light': '#EAF3EB',
  'light-red': '#FFF1EC',
  nero: '#222222',
  neutral: '#F3F3F6',
  'orange-dark': '#f75011',
  platinum: '#E4E4E4',
  silver: '#BABABA',
  'warning-dark': '#E99400',
  'warning-light': '#FDF4E5',
  'white-base': '#ffefe9',
  'olive-black': '#3B3B3B',
  'denim-blue': '#1E40AF',
  'error-base': '#DC2020',
  'light-gray': '#CBCBCB',
  'rich-black': '#020202',
  'info-base': '#017AAD',
  'info-light': '#E5F5FC',
  pumpkins: '#F38023',
  'misty-moss': '#FFCA39',
  'light-silver': '#D4D6D9',
  'royal-orange': '#F78C3E',
  'light-pink': '#E7ACBE',
  'charleston-green': '#272B30'
};

module.exports = {
  content: [
    './atoms/*.js',
    './atoms/**/*.js',
    './components/*.js',
    './components/**/*.js',
    './components/**/**/*.js',
    './components/**/**/**/*.js',
    './molecules/*.js',
    './molecules/**/*.js',
    './molecules/**/**/*.js',
    './molecules/**/**/**/*.js',
    './organisms/*.js',
    './organisms/**/*.js',
    './organisms/**/**/*.js',
    './organisms/**/**/**/*.js',
    './pages/*.js',
    './pages/**/*.js',
    './pages/**/**/*.js',
    './pages/**/**/**/*.js',
    './templates/*.js',
    './templates/**/*.js',
    './templates/**/**/*.js',
    './templates/**/**/**/*.js'
  ],
  theme: {
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px'
    },
    extend: {
      borderColor: () => ({
        ...colors
      }),

      backgroundColor: () => ({
        ...colors
      }),

      textColor: () => ({
        ...colors
      }),
      content: () => ({
        'searchkit-checkbox': `url('${staticMediaStoreBaseURL}/icons/square.svg')`,
        'searchkit-checkbox-checked': `url('${staticMediaStoreBaseURL}/icons/checked.svg')`,
        'searchkit-filter-menu-item-checked': `url('${staticMediaStoreBaseURL}/icons/radio-selected.svg')`
      }),
      backgroundImage: () => ({
        'stats-home': `url('${staticMediaStoreBaseURL}/icons/stats-bg.svg')`,
        'user-login': `url('${staticMediaStoreBaseURL}/icons/user-login-bg.svg')`,
        'searchkit-search-box-form': `url('${staticMediaStoreBaseURL}/icons/search-brand-icon.svg')`,
        'searchkit-filter-menu-item': `url('${staticMediaStoreBaseURL}/icons/radio.svg')`,
        'searchkit-filter-menu-item-checked': `url('${staticMediaStoreBaseURL}/icons/radio-selected.svg')`,
        profile: `url('${staticMediaStoreBaseURL}/icons/profile-web-bg.svg')`,

        'brand-gradient':
          'linear-gradient(66.43deg, #E58023 -33.51%, #CF578F 94.65%, #E03D24 207.46%);',
        'planner-cart-bg': `linear-gradient(66.43deg, #E58023 -33.51%, #CF578F 94.65%, #E03D24 207.46%),linear-gradient(90deg, #F38023 -90.01%, #DD579D 205.57%, #EE3D24 467.37%)`,
        'button-gradient':
          'linear-gradient(90deg, #F38023 -90.01%, #DD579D 205.57%, #EE3D24 467.37%)'
      }),
      boxShadow: () => ({
        card: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
        box: '0px 2px 8px 0px #00000014'
      }),
      minWidth: () => ({
        6: '1.5rem',
        8: '2rem',
        590: '36.875'
      }),
      height: () => ({
        0.5: '0.125rem',
        13.5: '52px',
        50: '12.5rem',
        26: '6.5rem'
      }),
      minHeight: () => ({
        80: '20rem',
        96: '37.7rem',
        280: '70rem'
      }),
      maxHeight: () => ({
        80: '20rem',
        96: '37.7rem',
        280: '70rem'
      }),
      width: () => ({
        13.5: '52px',
        37.5: '37.5rem',
        39.375: '39.375rem',
        112: '28rem',
        43.5: '43.5rem',
        41.5: '41.5rem',
        42: '42rem',
        47.5: '47.5rem',
        50: '50rem',
        51.5: '51.5rem',
        54.7: '54.7rem',
        58: '58rem',
        18: '4.5rem',
        82: '82rem',
        84: '21rem',
        1060: '66.25rem'
      }),
      maxWidth: () => ({
        36: '9rem',
        48: '12rem',
        56: '14rem',
        64: '16rem',
        80: '20rem',
        100: '25rem',
        130: '30rem'
      }),
      borderRadius: () => ({
        6.25: '100px',
        '4xl': '2rem'
      }),
      fontSize: () => ({
        '3.5xl': ['32px', '34px'],
        '2.5xl': ['28px', '30px'],
        xss: ['10px', '12px']
      }),
      zIndex: () => ({
        101: 101
      }),
      margin: {
        35: '35rem'
      }
    }
  },
  plugins: [tailwindcssRtl]
};
